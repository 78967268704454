import React from 'react';
import no_avatar from './style/no-avatar.png';
import s from './pageMaster.module.css';

const PageMasterHeader = ({ dataMaster }) => {
  return (
    <div className={s.header}>
      <div className={s.avatar}>
        {dataMaster[0].img.length > 0 ? (
          <img src={`https://bshop.kz/beauty/AvatarPhotos/${dataMaster[0].img}`} alt="avatar" />
        ) : (
          <img src={no_avatar} alt="avatar" />
        )}
      </div>
      <div className={s.info}>
        <div className={s.name_person}>{dataMaster[0].FIO}</div>
        <div className={s.proffesion_person}> мастер</div>
        <div className={s.info_subscribes}>
          <div>
            <div className={s.icon_info_subscribes}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.31579 3.31579C3.31579 5.14389 4.80347 6.63158 6.63158 6.63158C8.45968 6.63158 9.94737 5.14389 9.94737 3.31579C9.94737 1.48768 8.45968 0 6.63158 0C4.80347 0 3.31579 1.48768 3.31579 3.31579ZM13.2632 14V13.2632C13.2632 10.4197 10.9487 8.10526 8.10526 8.10526H5.15789C2.31368 8.10526 0 10.4197 0 13.2632V14H13.2632Z"
                  fill="url(#paint0_linear_102_5637)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_5637"
                    x1="13.2735"
                    y1="-6.40549e-07"
                    x2="-0.725721"
                    y2="0.779426"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D432D8" />
                    <stop offset="0.515625" stopColor="#FF608D" />
                    <stop offset="1" stopColor="#F4A582" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            -
          </div>
          <div>
            <div className={s.icon_info_subscribes}>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.263184 7C0.263184 5.14348 1.00068 3.36301 2.31344 2.05025C3.62619 0.737498 5.40667 0 7.26318 0C9.1197 0 10.9002 0.737498 12.2129 2.05025C13.5257 3.36301 14.2632 5.14348 14.2632 7C14.2632 8.85651 13.5257 10.637 12.2129 11.9497C10.9002 13.2625 9.1197 14 7.26318 14C5.40667 14 3.62619 13.2625 2.31344 11.9497C1.00068 10.637 0.263184 8.85651 0.263184 7ZM10.5588 5.7456C10.6239 5.68052 10.6755 5.60325 10.7107 5.51822C10.7459 5.43318 10.7641 5.34204 10.7641 5.25C10.7641 5.15796 10.7459 5.06682 10.7107 4.98178C10.6755 4.89675 10.6239 4.81948 10.5588 4.7544C10.4937 4.68932 10.4164 4.63769 10.3314 4.60247C10.2464 4.56724 10.1552 4.54912 10.0632 4.54912C9.97114 4.54912 9.88 4.56724 9.79497 4.60247C9.70993 4.63769 9.63267 4.68932 9.56758 4.7544L6.56318 7.7602L4.95878 6.1544C4.8937 6.08932 4.81644 6.03769 4.7314 6.00247C4.64637 5.96724 4.55522 5.94912 4.46318 5.94912C4.37114 5.94912 4.28 5.96724 4.19497 6.00247C4.10993 6.03769 4.03267 6.08932 3.96758 6.1544C3.9025 6.21948 3.85087 6.29675 3.81565 6.38178C3.78043 6.46682 3.7623 6.55796 3.7623 6.65C3.7623 6.74204 3.78043 6.83318 3.81565 6.91822C3.85087 7.00325 3.9025 7.08052 3.96758 7.1456L6.06758 9.2456C6.13261 9.31079 6.20985 9.36251 6.2949 9.3978C6.37994 9.43309 6.47111 9.45125 6.56318 9.45125C6.65526 9.45125 6.74643 9.43309 6.83147 9.3978C6.91651 9.36251 6.99376 9.31079 7.05878 9.2456L10.5588 5.7456Z"
                  fill="url(#paint0_linear_102_5638)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_5638"
                    x1="14.2741"
                    y1="-6.40549e-07"
                    x2="-0.497643"
                    y2="0.868128"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D432D8" />
                    <stop offset="0.515625" stopColor="#FF608D" />
                    <stop offset="1" stopColor="#F4A582" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            -
          </div>
          <div>
            <div className={s.icon_info_subscribes}>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.26318 13V5.5M13.2632 13V1M1.26318 13V10"
                  stroke="url(#paint0_linear_102_5639)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="bevel"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_5639"
                    x1="13.2726"
                    y1="0.999999"
                    x2="0.611047"
                    y2="1.74411"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D432D8" />
                    <stop offset="0.515625" stopColor="#FF608D" />
                    <stop offset="1" stopColor="#F4A582" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            -
          </div>
          <div>
            <div className={s.icon_info_subscribes}>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.25952 0.750004L7.26031 0.750003C8.32785 0.748885 9.37789 1.02155 10.3102 1.54201C11.2425 2.06246 12.0259 2.81334 12.5857 3.723C13.1455 4.63267 13.463 5.67074 13.5077 6.73812C13.5524 7.80551 13.323 8.86653 12.8413 9.81991C12.8316 9.83895 12.8228 9.85839 12.8149 9.87819C12.6807 10.2115 12.5875 10.5599 12.537 10.9157C12.5351 10.9294 12.5336 10.9431 12.5324 10.9568C12.4859 11.4982 12.5582 11.9842 12.6908 12.4261C12.2533 12.2949 11.773 12.2206 11.2407 12.2589C11.2242 12.2601 11.2077 12.2618 11.1913 12.2641C10.8059 12.3177 10.4294 12.4218 10.0712 12.5737C10.0569 12.5797 10.0429 12.5862 10.029 12.5931C9.16836 13.0227 8.22019 13.2475 7.25845 13.25C5.60233 13.2497 4.01403 12.5914 2.84283 11.4196C1.67137 10.2475 1.01318 8.65773 1.01318 7C1.01318 5.34227 1.67137 3.75252 2.84283 2.58045C4.01428 1.40839 5.60302 0.750004 7.25952 0.750004Z"
                  stroke="url(#paint0_linear_102_5640)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_5640"
                    x1="14.2741"
                    y1="-6.40549e-07"
                    x2="-0.497643"
                    y2="0.868128"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D432D8" />
                    <stop offset="0.515625" stopColor="#FF608D" />
                    <stop offset="1" stopColor="#F4A582" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            -
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageMasterHeader;
