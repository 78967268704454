import React from 'react';
import s from './ServiceInCatalog.module.css';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';
import { onItemGo, onScrollCatalog } from '../../../../redux/action';

const ServiceInCatalog = ({ name, photopub, img, publtext, publdatetime, id, shopid, FIO, sub, completed, rating }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const toItem = () => {
    dispatch(onItemGo(true));
    history.push(`/shopper/item?shopid=${shopid}&id=${id}`);
    dispatch(onScrollCatalog(window.pageYOffset));
  };
  
  const sub_w = sub || '';
  const sub_r = typeof sub_w === 'string' ? sub_w.substring(0,3) : '';

  const compl_e = completed || '';
  const compl_r = typeof compl_e === 'string' ? compl_e.substring(0,3) : '';
  


  return (
    <div className={s.item_bshop} key={uniqid()}>
      <div className={s.item__bshop_image}>
      <img
          src={
            img && img !== ''
              ? 'https://bshop.kz/beauty/ServicePhotos/' + img
              : 'https://bshop.kz/beauty/AvatarPhotos/0.png'
          }
          alt="logo"
        />
      </div>

    <div className={s.item__bshop_name}>{name}</div>

    </div>
  );
};

export default ServiceInCatalog;
