import React, { useEffect, useRef, useState } from 'react';
import s from './ServiceMasters.module.css';
import BeautyMastersChooseSlider from './Slider/BeautyMastersChooseSlider'
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { onSearchItemBshop, onShopGo, onScrollCatalog } from '../../../redux/action';
import ButtonOrange from '../../../companents/bshop/buttonOrange/ButtonOrange';
import history from '../../../companents/history';
import bshopApi from '../../../api/bshopApi';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import Calendar from 'react-calendar';
 
const MastersFilter = (props) => {
  const dispatch = useDispatch();
  const { value, onShopId, onCategoryId } = useSelector((state) => state.onSearchItemBshop);
  const [dropDownFilter, setDropDownFilter] = useState(false);
  const [dropDownDate, setDropDownDate] = useState(false);
  const [dropDownTime, setDropDownTime] = useState(false);
  const [onSearch, setOnSearch] = useState(value);
  const [onCategory, setOnCategory] = useState('');
  const [onShop, setOnShop] = useState('');
  const [categoryOnShop, setCategroyOnShop] = useState('');
  const selectShopIdOnlyCategory = useRef();
  const [shops, setShops] = useState([]); 
  const [category, setCategory] = useState(null);
  const [shopInfo, setShopInfo] = useState('');
  const [arrowUp, setArrowUp] = useState(false);
  const [onDate, setOnDate] = useState('Дата');
  // let Date = 'Дата'; 
 
  useEffect(() => {
    getShops();
    getCategory(onShopId);
    window.addEventListener('scroll', () => {
      window.pageYOffset > 400 ? setArrowUp(true) : setArrowUp(false);
    });

    return () => {
      setShops([]);
      setCategory([]);
    };
  }, []);

  const getShops = async () => {
    const responseItem = await bshopApi.fetchShops();
    setShops(responseItem);
  };

  const search = (value) => {
    setOnSearch(value);
    searchDeb(value, onShopId, onCategoryId);
    onShopId && onChangeCategory(onShopId);
  };

  const getCategory = async (id) => {
    const responseItem = await bshopApi.fetchCategories(id);
    setCategory(responseItem);
  };
  const searchDeb = React.useCallback(
    debounce((value, onShopId, onCategoryId) => {
      dispatch(onScrollCatalog(''));
      dispatch(
        onSearchItemBshop({
          value: value,
          end: 100,
          onShopId: onShopId,
          onCategoryId: onCategoryId,
        }),
      );
    }, 1000),
    [],
  );

  const onChangeShop = (value) => {
    dispatch(onScrollCatalog(''));

    setOnShop(value);
    dispatch(
      onSearchItemBshop({
        value: '',
        end: 100,
        onShopId: value,
        onCategoryId: onCategoryId,
      }),
    );

    const getShopInfo = async (id) => {
      const responseItem = await bshopApi.fetchShopInfo(id);
      setShopInfo(responseItem);
    };

    getShopInfo(value);
    setDropDownFilter(false);
    setDropDownDate(false)
    setDropDownTime(false)

    history.push(`/shopper/shopid=${value}`);
    dispatch(onShopGo(true));
    getCategory(value);
  };
  
  const onChangeCategory = (value) => {
    dispatch(onScrollCatalog(''));

    setOnCategory(value);
    !onShop.length > 0 &&
      setCategroyOnShop(
        selectShopIdOnlyCategory.current.options[selectShopIdOnlyCategory.current.selectedIndex]
          .dataset.shopid,
      );
  };

  const goToFilter = () => {
    dispatch(onScrollCatalog(''));
    dispatch(
      onSearchItemBshop({
        value: value,
        end: 100,
        onShopId: categoryOnShop,
        onCategoryId: onCategory,
      }),
    );
  };

  const callDay = (clikedDay) => {
    // console.log(dayjs(clikedDay).format('YYYY-MM-DD'))
    let Date = dayjs(clikedDay).format('YYYY-MM-DD');
    // console.log(Date)
    
    setOnDate(dayjs(clikedDay).format('YYYY-MM-DD'));
    };

    const timerange = {
      name: '1'
    };
  return (
    <>
      
         <>
          <div className={s.filterInItem}>
            <input
              type="text"
              className={s.inputSearch}
              placeholder="Поиск"
              value={onSearch}
              onChange={(e) => search(e.target.value)}
            />
            <svg
              className={s.inputImgSearch}
              width="18"
              height="18"
              stroke='2'
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9459 16.7062L12.1027 10.863C13.0094 9.69071 13.4999 8.25746 13.4999 6.74997C13.4999 4.94548 12.7957 3.25349 11.5222 1.97774C10.2487 0.701997 8.55221 0 6.74997 0C4.94773 0 3.25124 0.704247 1.97774 1.97774C0.701997 3.25124 0 4.94548 0 6.74997C0 8.55221 0.704247 10.2487 1.97774 11.5222C3.25124 12.7979 4.94548 13.4999 6.74997 13.4999C8.25746 13.4999 9.68846 13.0094 10.8607 12.1049L16.7039 17.9459C16.7211 17.9631 16.7414 17.9767 16.7638 17.9859C16.7862 17.9952 16.8102 18 16.8344 18C16.8587 18 16.8827 17.9952 16.9051 17.9859C16.9275 17.9767 16.9478 17.9631 16.9649 17.9459L17.9459 16.9672C17.9631 16.95 17.9767 16.9297 17.9859 16.9073C17.9952 16.8849 18 16.8609 18 16.8367C18 16.8124 17.9952 16.7884 17.9859 16.766C17.9767 16.7437 17.9631 16.7233 17.9459 16.7062ZM10.314 10.314C9.35996 11.2657 8.09546 11.7899 6.74997 11.7899C5.40448 11.7899 4.13998 11.2657 3.18599 10.314C2.23424 9.35996 1.70999 8.09546 1.70999 6.74997C1.70999 5.40448 2.23424 4.13773 3.18599 3.18599C4.13998 2.23424 5.40448 1.70999 6.74997 1.70999C8.09546 1.70999 9.36221 2.23199 10.314 3.18599C11.2657 4.13998 11.7899 5.40448 11.7899 6.74997C11.7899 8.09546 11.2657 9.36221 10.314 10.314Z"
                fill="#A8A8A8"
              />
            </svg>

            <div className={s.dropdown_filters} onClick={() => setDropDownFilter(!dropDownFilter)}>
              {!dropDownFilter ? (
                <svg xmlns="http://www.w3.org/2000/svg" 
                width="16" 
                height="16" 
                fill="#F3F3F3" 
                className="bi bi-sliders" 
                viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
                </svg>
              ) : (
                <svg
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 8L8 6.99382e-07L0 8" fill="#404246" />
                </svg>
              )}
            </div>
          </div></>

{dropDownFilter && (

  <><div className={s.open_filter}>
          <div className={s.dropdown_date} onClick={() => setDropDownDate(!dropDownDate)}>
            {!dropDownDate ? (

              <><p>{onDate}</p>
              <svg xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#653030"
                className="bi bi-sliders"
                viewBox="0 0 16 16"
                >
                {/* <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" /> */}
              </svg></>
            ) : (
              <p>{onDate}</p>
              // <svg
              //   width="16"
              //   height="8"
              //   viewBox="0 0 16 8"
              //   fill="none"
              //   xmlns="http://www.w3.org/2000/svg">
              //   {/* <path d="M16 8L8 6.99382e-07L0 8" fill="#404246" /> */}
              // </svg>
            )}
          </div>

          <select className={s.dropdown_time} id="mounth">
              <option value="Время">Время</option>
              <option value="9:00">9:00</option>
              <option value="10:00">10:00</option>
              <option value="11:00">11:00</option>
              <option value="12:00">12:00</option>
              <option value="13:00">13:00</option>
              <option value="14:00">14:00</option>
              <option value="15:00">15:00</option>
              <option value="16:00">16:00</option>
              <option value="17:00">17:00</option>
              <option value="18:00">18:00</option>
          </select> 




        </div>
        <div className={s.dropDownCalendar}>
        {dropDownDate && (
            <Calendar 
            onClickDay={(value) =>callDay(value)} />
          )}
        </div>
        <div className={s.btnWrapper}>
            <div className={s.beauty_button_confirm} onClick={() => goToFilter()}>
              Применить
            </div>

          </div></>
)}
       
    </>
  );
};

export default MastersFilter;
