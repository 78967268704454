import React, { useState } from 'react';
import '../../pages/beauty/style/Registration_form_style.css';
import Registration_text_field from '../../pages/beauty/Registration_text_field';
import { ToastContainer, toast } from 'react-toast';
import { useDispatch } from 'react-redux';

import { accountBeauty } from '../../redux/action';
import { useHistory } from 'react-router-dom';

const SignIn = () => {
  const dispatch = useDispatch();
  const [Phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [PhoneError, setPhoneError] = useState('');

  const history = useHistory();

  const wave_error = () => {
    if (Phone === '') {
      toast('Ошибка! Пустое поле Телефона', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (Boolean(PhoneError) === true) {
      toast('Ошибка! Некорректно введенное поле Телефон', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (password === '') {
      toast('Ошибка! Пустое поле пароль', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    }
  };

  const wave_success = () =>
    toast('Отлично! Вы успешно авторизовались!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });
  const wave_error_sign_in = () =>
    toast('Ошибка входа! Вы ввели  неверные данные!', {
      backgroundColor: '#323131',
      color: '#ffffff',
    });

  const passwordd = (event) => {
    setPassword(event.target.value);
  };

  const onPhoneChange = (event) => {
    setPhone(event.target.value);

    var old = 0;

    var curLen = event.target.value.length;

    if (curLen < old) {
      old--;
      return;
    }

    if (curLen === 1) event.target.value = event.target.value = '+7 (';

    if (curLen === 7) event.target.value = event.target.value + ')-';

    if (curLen === 12) event.target.value = event.target.value + '-';

    if (curLen === 15) event.target.value = event.target.value + '-';

    if (curLen > 18) event.target.value = event.target.value.substring(0, 17);
    old++;
  };

  const SendRegForm = async (e) => {
    e.preventDefault();
    if (Boolean(PhoneError) === false && Phone.length === 18) {
      const regdata = new FormData();

      regdata.append('phone', Phone);
      regdata.append('pass', password);

      let response = await fetch(`https://bshop.kz/beauty/beautyApi/profil/logIn.php`, {
        method: 'POST',
        body: regdata,
      });

      let commits = await response.json(); // читаем ответ в формате JSON
      if (commits) {
        wave_success();
        dispatch(accountBeauty(commits[0]));
        localStorage.setItem('account', JSON.stringify({ login: Phone, pass: password }));
        localStorage.setItem('avatar', commits[0].img);
      } else {
        wave_error_sign_in();
      }
    } else {
      wave_error();
    }
  };
  const goToReg = () => {
    history.push('/beauty/registration');
  };
  return (
    <div className="reg_body">
      <div className="reg_wrapper">
        <h1 className="reg_title">Авторизация</h1>

        <div className="registration_form">
          <form name="registration" className="PhoneInputReset">
            <Registration_text_field
              name="Phone"
              onChange={(e) => onPhoneChange(e)}
              error={PhoneError}
              placeholder="Телефон"
              className="reg_placeholder_phone"
            />
            <button type="reset" className="reg_reset_btn"></button>
          </form>

          <Registration_text_field
            name="password"
            onChange={(e) => passwordd(e)}
            error="none"
            placeholder="Пароль"
            className="reg_placeholder"
          />

          <div className="inputGroup">
            <button
              onClick={(e) => SendRegForm(e)}
              className="reg_btn"
              style={{ marginBottom: 20 }}>
              Вход
            </button>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <ToastContainer position="center" />
            </div>
            <button
              className="reg_abs"
              style={{ textAlign: 'center', marginBottom: 50 }}
              onClick={() => goToReg()}>
              Регистрация
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
