const baseUrl = 'https://bshop.kz/api/bikliApi/';
const baseBeautyUrl = 'https://bshop.kz/beauty/beautyApi/';
// const baseUrl = 'https://bikli.kz/bikliApiBshop/';

const bshopApi = {
  fetchItems: async (counItems, onSearch, shopid, category) => {
    const response = await fetch(
      baseUrl +
        `getItems.php?start=0&end=${counItems}${onSearch && '&nameitem=' + onSearch}${
          shopid && '&shopid=' + shopid
        }${category && '&category=' + category}`,
    );
    const data = response.json();
    return data;
  },

  fetchPublications: async (counItems, onSearch) => {
    const response = await fetch(
      baseUrl +
        `getPublications.php?start=0&end=${counItems}${onSearch && '&nameitem=' + onSearch}`,
    );
    const data = response.json();
    return data;
  },

  fetchMastersInCatalog: async (counItems, onSearch, profName) => {
    const response = await fetch(
      baseBeautyUrl + `getCategory.php?start=0&end=${counItems}&profName=${profName}`,
    );
    const data = response.json();
    return data;
  },

  fetchServicesInCatalog: async (counItems, onSearch, profName) => {
    const response = await fetch(
      baseBeautyUrl + `PullOrderCat.php?start=0&end=${counItems}&prof=${profName}`,
    );
    const data = response.json();
    return data;
  },

  fetchItem: async (id, shopId) => {
    const response = await fetch(baseUrl + `getIteminfo.php?itemid=${id}&shopid=${shopId}`);
    const data = await response.json();
    return data;
  },

  fetchSizes: async (shopId, name) => {
    const response = await fetch(baseUrl + `getSize.php?name=${name}&shopid=${shopId}`);
    const data = response.json();
    return data;
  },
  fetchShops: async () => {
    const response = await fetch(baseUrl + `getShops.php`);
    const data = response.json();
    return data;
  },
  fetchCategories: async (id = '') => {
    const response = await fetch(
      baseUrl + `getCategory.php?${id.length > 0 ? 'shopid=' + id : null}`,
    );
    const data = response.json();
    return data;
  },

  fetchShopInfo: async (id) => {
    const response = await fetch(baseUrl + `getShopInfo.php?shopid=${id}`);
    const data = response.json();
    return data;
  },

  toOrder: async (fd) => {
    const response = await fetch(baseUrl + `order.php`, { method: 'POST', body: fd });
    await fetch(baseUrl + `TgBOT.php`, { method: 'POST', body: fd });
    const data = response.json();
    return data;
  },
};

export default bshopApi;
