import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { onSearchItemBshop, onShopGo } from '../../redux/action';

import avatar from '../../pages/beauty/style/no-avatar.png';

import s from './footer.module.css';
const Footer = ({ type }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // BSHOP START ------------------------------------------------------------------------>
  const counCart = useSelector((state) => state.basketBshop);
  const [checkItem, setCheckItem] = useState('/shopper');
  useEffect(() => {
    location.pathname === '/cart' && setCheckItem(`/cart`);
    location.pathname === '/shopper' && setCheckItem(`/shopper`);
    location.pathname === '/favorites' && setCheckItem(`/favorites`);
  }, [location.pathname]);

  // BSHOP END ------------------------------------------------------------------------>

  // BEAUTY START ------------------------------------------------------------------------>
  const accountBeauty = useSelector((state) => state.accountBeauty);
  const [checkItemBeauty, setCheckItemBeauty] = useState('/beauty');
  useEffect(() => {
    location.pathname === '/profile' && setCheckItemBeauty(`/profile`);
    location.pathname === '/beauty' && setCheckItemBeauty(`/beauty`);
    location.pathname === '/favorites' && setCheckItemBeauty(`/favorites`);
  }, [location.pathname]);

  // BEAUTY END ------------------------------------------------------------------------>
  if (type === 'bshop') {
    const clickItem = (path) => {
      if (path === '/shopper') {
        dispatch(
          onSearchItemBshop({
            value: '',
            end: 20,
            onShopId: '',
            onCategoryId: '',
          }),
        );
        history.push(path);
        setCheckItem(path);
        dispatch(onShopGo(false));
      } else {
        history.push(path);
        setCheckItem(path);
      }
    };

    return (
      <div className={s.root}>
        <div className={s.footer__item} onClick={() => clickItem(`/`)}>
          <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.83333 9.21404V22.267H20.1667V9.21404L11 1.99436L1.83333 9.21404ZM0.33 8.14305L10.4133 0.200703C10.578 0.0710171 10.7856 0 11 0C11.2144 0 11.422 0.0710171 11.5867 0.200703L21.67 8.14305C21.7732 8.22433 21.8563 8.32607 21.9133 8.44107C21.9704 8.55607 22 8.68151 22 8.80852V23.1335C22 23.3633 21.9034 23.5837 21.7315 23.7462C21.5596 23.9087 21.3264 24 21.0833 24H0.916667C0.673552 24 0.440394 23.9087 0.268486 23.7462C0.0965773 23.5837 0 23.3633 0 23.1335V8.80852C5.03802e-05 8.68151 0.0296362 8.55607 0.0866627 8.44107C0.143689 8.32607 0.226762 8.22433 0.33 8.14305Z"
              fill="#F3F3F3"
            />
          </svg>
        </div>
        <div className={s.footer__item} onClick={() => clickItem(`/shopper`)}>
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 20H22V18H0V20ZM0 0V2H22V0H0ZM0 11H22V9H11H0V11Z"
              fill={checkItem === '/shopper' ? '#ff7a00' : '#ffff'}
            />
          </svg>
        </div>

        <div className={s.footer__item} onClick={() => clickItem(`/favorites`)}>
          <svg
            width="25"
            height="22"
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M24.7849 4.9684C24.6409 4.404 24.4185 3.86258 24.1243 3.35996C23.8417 2.83709 23.4803 2.36089 23.0529 1.9481C22.4333 1.32999 21.6994 0.838334 20.8923 0.500507C19.2677 -0.166836 17.4458 -0.166836 15.8212 0.500507C15.0583 0.823693 14.3575 1.27724 13.7499 1.84087L13.6606 1.9481L12.5 3.10976L11.3394 1.9481L11.2501 1.84087C10.6425 1.27724 9.94165 0.823693 9.17877 0.500507C7.55416 -0.166836 5.73228 -0.166836 4.10767 0.500507C3.30054 0.838334 2.56674 1.32999 1.94709 1.9481C1.09957 2.77347 0.499626 3.81965 0.215053 4.9684C0.0636605 5.55176 -0.00841351 6.1529 0.000780446 6.75555C0.000780446 7.32208 0.0722045 7.88682 0.215053 8.43548C0.364746 8.98958 0.580573 9.52361 0.85787 10.0261C1.15734 10.5426 1.52357 11.0173 1.94709 11.4379L12.5 22L23.0529 11.4379C23.4761 11.0215 23.8386 10.5443 24.1243 10.0261C24.7042 9.03437 25.0065 7.9046 24.9992 6.75555C25.0085 6.1529 24.9364 5.55175 24.7849 4.9684ZM22.9993 7.88146C22.7855 8.69805 22.3605 9.44387 21.7673 10.0439L12.4643 19.3371L3.1613 10.0439C2.85814 9.73835 2.59422 9.39615 2.37563 9.02524C2.16994 8.6583 2.00812 8.2684 1.89352 7.86359C1.80202 7.45883 1.75414 7.04543 1.75067 6.63045C1.75309 6.20361 1.80098 5.77825 1.89352 5.36157C2.00476 4.9556 2.16675 4.5653 2.37563 4.19992C2.5899 3.82462 2.85417 3.48506 3.1613 3.18124C3.62001 2.72828 4.1589 2.36466 4.75048 2.10895C5.94257 1.63164 7.27244 1.63164 8.46453 2.10895C9.05378 2.35379 9.58589 2.71301 10.0359 3.16337L12.4643 5.61177L14.8927 3.16337C15.3424 2.71221 15.8763 2.35394 16.464 2.10895C17.6561 1.63164 18.986 1.63164 20.1781 2.10895C20.7691 2.36451 21.3084 2.72909 21.7673 3.18124C22.078 3.47612 22.3387 3.81926 22.5351 4.19992C22.9487 4.92991 23.1641 5.75554 23.16 6.59471C23.1843 7.02653 23.1483 7.45963 23.0529 7.88146H22.9993Z"
              fill={checkItem === '/favorites' ? '#ff7a00' : '#ffff'}
            />
          </svg>
        </div>
        <div className={s.footer__item} onClick={() => clickItem(`/cart`)}>
          <div className={s.cart_footer_item}>
            {counCart.length > 0 && <div className={s.coun_items_cart}>{counCart.length}</div>}
            <svg
              width="27"
              height="26"
              viewBox="0 0 27 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.66982 25C10.6834 25 11.505 24.1783 11.505 23.1648C11.505 22.1512 10.6834 21.3296 9.66982 21.3296C8.65625 21.3296 7.8346 22.1512 7.8346 23.1648C7.8346 24.1783 8.65625 25 9.66982 25Z"
                fill={checkItem === '/cart' ? '#ff7a00' : '#ffff'}
              />
              <path
                d="M20.8198 25C21.8333 25 22.655 24.1783 22.655 23.1648C22.655 22.1512 21.8333 21.3296 20.8198 21.3296C19.8062 21.3296 18.9846 22.1512 18.9846 23.1648C18.9846 24.1783 19.8062 25 20.8198 25Z"
                fill={checkItem === '/cart' ? '#ff7a00' : '#ffff'}
              />
              <path
                d="M25.779 3.27918C25.7031 3.18561 25.6074 3.11002 25.4988 3.05784C25.3902 3.00566 25.2714 2.97819 25.1509 2.97739H8.16902L8.69919 4.60869H24.0824L21.9046 14.3965H9.66982L5.94229 2.59403C5.90197 2.4688 5.83195 2.35517 5.73821 2.26285C5.64447 2.17053 5.52979 2.10226 5.40396 2.06386L2.05978 1.03614C1.95695 1.00454 1.84891 0.993502 1.74181 1.00366C1.63472 1.01382 1.53068 1.04497 1.43562 1.09534C1.24365 1.19706 1.09995 1.37087 1.03614 1.57854C0.972322 1.78622 0.993617 2.01073 1.09534 2.2027C1.19706 2.39467 1.37087 2.53837 1.57855 2.60219L4.50674 3.49941L8.25058 15.3264L6.91291 16.4193L6.80688 16.5254C6.476 16.9067 6.28845 17.3914 6.27654 17.8962C6.26463 18.4009 6.4291 18.8939 6.74163 19.2904C6.96394 19.5608 7.24644 19.7754 7.56653 19.917C7.88661 20.0586 8.2354 20.1234 8.585 20.1061H22.1982C22.4146 20.1061 22.622 20.0202 22.775 19.8672C22.928 19.7142 23.0139 19.5068 23.0139 19.2904C23.0139 19.0741 22.928 18.8666 22.775 18.7137C22.622 18.5607 22.4146 18.4748 22.1982 18.4748H8.4545C8.36057 18.4716 8.26906 18.4442 8.1888 18.3953C8.10855 18.3464 8.04226 18.2776 7.99634 18.1956C7.95042 18.1136 7.92642 18.0212 7.92667 17.9272C7.92691 17.8332 7.95139 17.7409 7.99773 17.6591L9.96345 16.0278H22.5571C22.7457 16.0324 22.93 15.9716 23.0787 15.8556C23.2274 15.7396 23.3313 15.5756 23.3728 15.3916L25.9584 3.97248C25.9832 3.85089 25.9799 3.72523 25.9488 3.60508C25.9177 3.48494 25.8596 3.37348 25.779 3.27918Z"
                fill={checkItem === '/cart' ? '#ff7a00' : '#ffff'}
              />
              <path
                d="M9.66982 25C10.6834 25 11.505 24.1783 11.505 23.1648C11.505 22.1512 10.6834 21.3296 9.66982 21.3296C8.65625 21.3296 7.8346 22.1512 7.8346 23.1648C7.8346 24.1783 8.65625 25 9.66982 25Z"
                stroke="#404246"
                strokeWidth="0.8"
              />
              <path
                d="M20.8198 25C21.8333 25 22.655 24.1783 22.655 23.1648C22.655 22.1512 21.8333 21.3296 20.8198 21.3296C19.8062 21.3296 18.9846 22.1512 18.9846 23.1648C18.9846 24.1783 19.8062 25 20.8198 25Z"
                stroke="#404246"
                strokeWidth="0.8"
              />
              <path
                d="M25.779 3.27918C25.7031 3.18561 25.6074 3.11002 25.4988 3.05784C25.3902 3.00566 25.2714 2.97819 25.1509 2.97739H8.16902L8.69919 4.60869H24.0824L21.9046 14.3965H9.66982L5.94229 2.59403C5.90197 2.4688 5.83195 2.35517 5.73821 2.26285C5.64447 2.17053 5.52979 2.10226 5.40396 2.06386L2.05978 1.03614C1.95695 1.00454 1.84891 0.993502 1.74181 1.00366C1.63472 1.01382 1.53068 1.04497 1.43562 1.09534C1.24365 1.19706 1.09995 1.37087 1.03614 1.57854C0.972322 1.78622 0.993617 2.01073 1.09534 2.2027C1.19706 2.39467 1.37087 2.53837 1.57855 2.60219L4.50674 3.49941L8.25058 15.3264L6.91291 16.4193L6.80688 16.5254C6.476 16.9067 6.28845 17.3914 6.27654 17.8962C6.26463 18.4009 6.4291 18.8939 6.74163 19.2904C6.96394 19.5608 7.24644 19.7754 7.56653 19.917C7.88661 20.0586 8.2354 20.1234 8.585 20.1061H22.1982C22.4146 20.1061 22.622 20.0202 22.775 19.8672C22.928 19.7142 23.0139 19.5068 23.0139 19.2904C23.0139 19.0741 22.928 18.8666 22.775 18.7137C22.622 18.5607 22.4146 18.4748 22.1982 18.4748H8.4545C8.36057 18.4716 8.26906 18.4442 8.1888 18.3953C8.10855 18.3464 8.04226 18.2776 7.99634 18.1956C7.95042 18.1136 7.92642 18.0212 7.92667 17.9272C7.92691 17.8332 7.95139 17.7409 7.99773 17.6591L9.96345 16.0278H22.5571C22.7457 16.0324 22.93 15.9716 23.0787 15.8556C23.2274 15.7396 23.3313 15.5756 23.3728 15.3916L25.9584 3.97248C25.9832 3.85089 25.9799 3.72523 25.9488 3.60508C25.9177 3.48494 25.8596 3.37348 25.779 3.27918Z"
                stroke="#404246"
                strokeWidth="0.8"
              />
            </svg>
          </div>
        </div>
        <div className={s.footer__item}>
          <div className={s.profile}></div>
        </div>
      </div>
    );
  }
  if (type === 'beauty') {
    const clickItem = (path) => {
      history.push(path);
      setCheckItemBeauty(path);
    };
    return (
      <div className={s.root_beauty}>
        <div className={s.footer__item} onClick={() => clickItem(`/`)}>
          <svg
            width="22"
            height="24"
            viewBox="0 0 22 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.83333 9.21404V22.267H20.1667V9.21404L11 1.99436L1.83333 9.21404ZM0.33 8.14305L10.4133 0.200703C10.578 0.0710171 10.7856 0 11 0C11.2144 0 11.422 0.0710171 11.5867 0.200703L21.67 8.14305C21.7732 8.22433 21.8563 8.32607 21.9133 8.44107C21.9704 8.55607 22 8.68151 22 8.80852V23.1335C22 23.3633 21.9034 23.5837 21.7315 23.7462C21.5596 23.9087 21.3264 24 21.0833 24H0.916667C0.673552 24 0.440394 23.9087 0.268486 23.7462C0.0965773 23.5837 0 23.3633 0 23.1335V8.80852C5.03802e-05 8.68151 0.0296362 8.55607 0.0866627 8.44107C0.143689 8.32607 0.226762 8.22433 0.33 8.14305Z"
              fill="#ffff"
            />
          </svg>
        </div>
        <div className={s.footer__item} onClick={() => clickItem(`/beauty`)}>
          <svg
            width="24"
            height="22"
            viewBox="0 0 24 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 21H23V19H1V21ZM1 1V3H23V1H1ZM1 12H23V10H12H1V12Z"
              fill={checkItemBeauty === '/beauty' ? '#FC5992' : '#ffff'}
              stroke="#404246"
            />
          </svg>
        </div>

        <div className={s.footer__item} onClick={() => clickItem(`/beauty/favorites`)}>
          <svg
            width="25"
            height="22"
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M24.7849 4.9684C24.6409 4.404 24.4185 3.86258 24.1243 3.35996C23.8417 2.83709 23.4803 2.36089 23.0529 1.9481C22.4333 1.32999 21.6994 0.838334 20.8923 0.500507C19.2677 -0.166836 17.4458 -0.166836 15.8212 0.500507C15.0583 0.823693 14.3575 1.27724 13.7499 1.84087L13.6606 1.9481L12.5 3.10976L11.3394 1.9481L11.2501 1.84087C10.6425 1.27724 9.94165 0.823693 9.17877 0.500507C7.55416 -0.166836 5.73228 -0.166836 4.10767 0.500507C3.30054 0.838334 2.56674 1.32999 1.94709 1.9481C1.09957 2.77347 0.499626 3.81965 0.215053 4.9684C0.0636605 5.55176 -0.00841351 6.1529 0.000780446 6.75555C0.000780446 7.32208 0.0722045 7.88682 0.215053 8.43548C0.364746 8.98958 0.580573 9.52361 0.85787 10.0261C1.15734 10.5426 1.52357 11.0173 1.94709 11.4379L12.5 22L23.0529 11.4379C23.4761 11.0215 23.8386 10.5443 24.1243 10.0261C24.7042 9.03437 25.0065 7.9046 24.9992 6.75555C25.0085 6.1529 24.9364 5.55175 24.7849 4.9684ZM22.9993 7.88146C22.7855 8.69805 22.3605 9.44387 21.7673 10.0439L12.4643 19.3371L3.1613 10.0439C2.85814 9.73835 2.59422 9.39615 2.37563 9.02524C2.16994 8.6583 2.00812 8.2684 1.89352 7.86359C1.80202 7.45883 1.75414 7.04543 1.75067 6.63045C1.75309 6.20361 1.80098 5.77825 1.89352 5.36157C2.00476 4.9556 2.16675 4.5653 2.37563 4.19992C2.5899 3.82462 2.85417 3.48506 3.1613 3.18124C3.62001 2.72828 4.1589 2.36466 4.75048 2.10895C5.94257 1.63164 7.27244 1.63164 8.46453 2.10895C9.05378 2.35379 9.58589 2.71301 10.0359 3.16337L12.4643 5.61177L14.8927 3.16337C15.3424 2.71221 15.8763 2.35394 16.464 2.10895C17.6561 1.63164 18.986 1.63164 20.1781 2.10895C20.7691 2.36451 21.3084 2.72909 21.7673 3.18124C22.078 3.47612 22.3387 3.81926 22.5351 4.19992C22.9487 4.92991 23.1641 5.75554 23.16 6.59471C23.1843 7.02653 23.1483 7.45963 23.0529 7.88146H22.9993Z"
              fill={checkItemBeauty === '/beauty/favorites' ? '#FC5992' : '#ffff'}
            />
          </svg>
        </div>

        <div className={s.footer__item} onClick={() => clickItem(`/beauty/profile`)}>
          <div
            className={s.profile}
            style={
              checkItemBeauty === '/beauty/profile'
                ? { border: '2px solid #FC5992 ' }
                : { border: '2px solid #ffff ' }
            }>
            {accountBeauty.img && accountBeauty.img != '' ? (
              <img
                src={`https://bshop.kz/beauty/AvatarPhotos/${accountBeauty.img}`}
                alt={'avatar'}
              />
            ) : (
              <img src={avatar} alt={'avatar'} />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default React.memo(Footer);
