import React, { useEffect, useRef, useState } from 'react';
import s from './ServiceMasters.module.css';
import 'react-calendar/dist/Calendar.css';
import MastersWrapper from './MastersCatalog/MastersWrapper';
import ServicesWrapper from './ServicesCatalog/ServicesWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { onShowMasters, ServBorderType, MasterBorderType, ServBorderImg, MasterBorderImg } from '../../../redux/action';

const ServicesOrMasters = (props) => {
    const dispatch = useDispatch();
    const ShowHide = useSelector((state) => state.onShowMasters);
    // console.log('ShowHide: ' + ShowHide)
    
    const choice_of_services_Sborder = useSelector((state) => state.SBroderType);
    const choice_of_services_Mborder = useSelector((state) => state.MBroderType);

    const choice_of_services_SborderImg = useSelector((state) => state.SBorderImage);
    const choice_of_services_MborderImg = useSelector((state) => state.MBorderImage);

    const ShowS = (val) => {
        dispatch(onShowMasters(val));
       // console.log('ShowHide: ' + ShowHide)
        dispatch(ServBorderType('4px solid transparent'));
        dispatch(MasterBorderType('0.5px solid #404246'));
        dispatch(ServBorderImg('linear-gradient(to left, #D432D8 -0.07%, #FF608D 51.49%, #F4A582 99.93%)  1 / 1 / 0 stretch'));
        dispatch(MasterBorderImg('none 1 / 1 / 0 stretch'));
    }

    const ShowM = (val) => {
        dispatch(onShowMasters(val));
       // console.log('ShowHide: ' + ShowHide)
        dispatch(ServBorderType('0.5px solid #404246'));
        dispatch(MasterBorderType('4px solid transparent'));
        dispatch(ServBorderImg('none 1 / 1 / 0 stretch'));
        dispatch(MasterBorderImg('linear-gradient(to left, #D432D8 -0.07%, #FF608D 51.49%, #F4A582 99.93%)  1 / 1 / 0 stretch'));
    }
  
  return (
            <>
            <div className={s.choice_of_services_and_masters}>

                <div className={s.choice_of_services} 
                style={{
                    borderBottom: `${choice_of_services_Sborder}`, 
                    borderImage: `${choice_of_services_SborderImg}`,
                    borderImageSlice: 1}} 
                    onClick={() => ShowS(false)}>
                        <p>Услуги</p>
                </div>

                <div className={s.choice_of_masters} 
                style={{
                    borderBottom: `${choice_of_services_Mborder}`,
                    borderImage: `${choice_of_services_MborderImg}`,
                    borderImageSlice: 1}}  
                onClick={() => ShowM(true)}>
                    <p>Мастера</p>
                </div>

            </div>

            {ShowHide && <MastersWrapper />}
            {!ShowHide && <ServicesWrapper />}
            </>
          
)
       
  

};

export default ServicesOrMasters;
