import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ContentLoader from 'react-content-loader';
import s from './pageMaster.module.css';
import PageMasterHeader from './PageMasterHeader';

import beautyApi from '../../api/beautyApi';
import LoadingBlock from '../../companents/bshop/itemCatalogBshop/oneItem/LoadingBlock';

const PageMaster = () => {
  const dispatch = useDispatch();

  const [dataMaster, setDataMaster] = useState();
  const [dataBlog, setDataBlog] = useState();
  const getDataMaster = async (id) => {
    const fd = new FormData();
    fd.append('id', id);
    const response = await beautyApi.fetchDataMaster(fd);
    await setDataMaster(response);
    const responseBlog = await beautyApi.fetchBlogMaster(fd);
    setDataBlog(responseBlog);
  };

  const [toggleAddress, setToggleAddress] = useState(false);
  const [tab, setTab] = useState(1);

  const tabs = ['услуги', 'блог', 'акции', 'отзывы'];

  const urlPath = window.location.search
    .replace('?', '')
    .split('&')
    .reduce(function (p, e) {
      const a = e.split('=');
      p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
      return p;
    }, {});

  useEffect(() => {
    getDataMaster(urlPath.id);
  }, []);

  if (dataMaster) {
    return (
      <>
        <PageMasterHeader dataMaster={dataMaster} />
        <div className={s.buttons_person}>
          <div className={s.button_subscribe} style={{ opacity: 0.6 }}>
            <div>Подписаться</div>
          </div>
          <div className={s.button_write} style={{ opacity: 0.6 }}>
            Записаться
          </div>
          <div className={s.button_search} style={{ opacity: 0.6 }}>
            <div>
              <svg
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.4579 12.9937L9.91321 8.44896C10.6185 7.53722 11 6.42247 11 5.24998C11 3.84648 10.4522 2.53049 9.46171 1.53824C8.47122 0.545998 7.15172 0 5.74998 0C4.34823 0 3.02874 0.547748 2.03824 1.53824C1.046 2.52874 0.5 3.84648 0.5 5.24998C0.5 6.65172 1.04775 7.97122 2.03824 8.96171C3.02874 9.95396 4.34648 10.5 5.74998 10.5C6.92247 10.5 8.03547 10.1185 8.94721 9.41496L13.4919 13.9579C13.5053 13.9713 13.5211 13.9819 13.5385 13.9891C13.5559 13.9963 13.5746 14 13.5934 14C13.6123 14 13.631 13.9963 13.6484 13.9891C13.6658 13.9819 13.6816 13.9713 13.6949 13.9579L14.4579 13.1967C14.4713 13.1834 14.4819 13.1675 14.4891 13.1501C14.4963 13.1327 14.5 13.114 14.5 13.0952C14.5 13.0763 14.4963 13.0577 14.4891 13.0403C14.4819 13.0228 14.4713 13.007 14.4579 12.9937ZM8.52197 8.02197C7.77997 8.76221 6.79647 9.16996 5.74998 9.16996C4.70348 9.16996 3.71999 8.76221 2.97799 8.02197C2.23774 7.27997 1.82999 6.29647 1.82999 5.24998C1.82999 4.20348 2.23774 3.21824 2.97799 2.47799C3.71999 1.73774 4.70348 1.32999 5.74998 1.32999C6.79647 1.32999 7.78172 1.73599 8.52197 2.47799C9.26221 3.21999 9.66996 4.20348 9.66996 5.24998C9.66996 6.29647 9.26221 7.28172 8.52197 8.02197Z"
                  fill="#404246"
                  fillOpacity="0.7"
                />
              </svg>
            </div>
          </div>
        </div>
        {toggleAddress ? <div>Small slider</div> : null}
        <div className={s.content}>
          <div className={s.content_address}>
            <div className={s.content_address_geo}>
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.0909 7.54545C14.0909 12.6364 7.54545 17 7.54545 17C7.54545 17 1 12.6364 1 7.54545C1 5.80949 1.68961 4.14463 2.91712 2.91712C4.14463 1.68961 5.80949 1 7.54545 1C9.28142 1 10.9463 1.68961 12.1738 2.91712C13.4013 4.14463 14.0909 5.80949 14.0909 7.54545Z"
                  stroke="url(#paint0_linear_102_5165)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.54545 9.72727C8.75044 9.72727 9.72727 8.75044 9.72727 7.54545C9.72727 6.34047 8.75044 5.36364 7.54545 5.36364C6.34047 5.36364 5.36364 6.34047 5.36364 7.54545C5.36364 8.75044 6.34047 9.72727 7.54545 9.72727Z"
                  stroke="url(#paint1_linear_102_5165)"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_5165"
                    x1="14.1011"
                    y1="0.999999"
                    x2="0.272843"
                    y2="1.66492"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D432D8" />
                    <stop offset="0.515625" stopColor="#FF608D" />
                    <stop offset="1" stopColor="#F4A582" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_102_5165"
                    x1="14.1011"
                    y1="0.999999"
                    x2="0.272843"
                    y2="1.66492"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="#D432D8" />
                    <stop offset="0.515625" stopColor="#FF608D" />
                    <stop offset="1" stopColor="#F4A582" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className={s.content_address_text}>{dataMaster[0].Adress}</div>
            {/* <div>
            {!toggleAddress ? (
              <svg
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M0 -5.24537e-07L6 6L12 0" fill="#404246" />
              </svg>
            ) : (
              <svg
                width="12"
                height="6"
                viewBox="0 0 12 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M12 6L6 -5.24537e-07L0 6" fill="#404246" />
              </svg>
            )}
          </div> */}
          </div>
          <div className={s.tabs_master}>
            <div className={s.name_tabs}>
              {/* <div key={key} onClick={() => setTab(key)} className={key === tab && s.active_tab}> */}

              {tabs.map((item, key) => (
                <>
                  <div key={key}></div>
                </>
              ))}
            </div>
            <div className={s.tabs}>
              {tab === 0 ? (
                <div className={s.tab}>услуги</div>
              ) : tab === 1 ? (
                <div className={s.tab} style={{ marginTop: '18px' }}>
                  <div className={s.tab_blog}>
                    {dataBlog
                      ? dataBlog?.map((item, key) => (
                          <div key={key}>
                            <img
                              src={`https://bshop.kz/beauty/PublicationPhotos/${item.photopub}`}
                              alt={item.publtext}
                            />
                          </div>
                        ))
                      : Array.from(Array(9), (_, i) => (
                          <div key={i}>
                            <ContentLoader
                              speed={2}
                              width={425}
                              height={433}
                              viewBox="0 0 425 433"
                              backgroundColor="#f3f3f3"
                              foregroundColor="#d6d6d6">
                              <rect x="0" y="465" rx="0" ry="0" width="30vw" height="30vw" />
                              <rect x="0" y="575" rx="0" ry="0" width="30vw" height="30vw" />
                              <rect x="0" y="680" rx="0" ry="0" width="30vw" height="30vw" />
                              <rect x="231" y="444" rx="0" ry="0" width="41" height="1" />
                              <rect x="293" y="559" rx="0" ry="0" width="30vw" height="30vw" />
                              <rect x="3" y="8" rx="0" ry="0" width="  30vw" height="30vw" />
                            </ContentLoader>
                          </div>
                        ))}
                  </div>
                </div>
              ) : tab === 2 ? (
                <div className={s.tab}>акции</div>
              ) : (
                <div className={s.tab}>отзывы</div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <LoadingBlock />
      </>
    );
  }
};

export default PageMaster;
