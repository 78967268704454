import React, { useEffect, useRef, useState } from 'react';
import s from './req.module.css';
import { useHistory } from 'react-router-dom';
import uniqid from 'uniqid';
import { useDispatch } from 'react-redux';
import { onItemGo, onScrollCatalog } from '../../../redux/action';
import Popup from 'reactjs-popup';
import AcceptModul from './AcceptModul';

const ItemClientRequest = ({ FIO, date, id, orderName, phome, price, status, time, comm, img, clientId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dropDownComm, setDropDownComm] = useState(false);
  const [dropDownReqAccept, setDropDownReqAccept] = useState(false);

  const toItem = () => {
    dispatch(onItemGo(true));
    dispatch(onScrollCatalog(window.pageYOffset));
  };
  
  const ReqAccept = () => {
    setDropDownReqAccept(!dropDownReqAccept);
  }

  return (
 <div>
    <div className={s.item_client_req_info_id}>{id}</div>
    <div className={s.item_bshop} key={uniqid()} >
          <div className={s.item__bshop_image} onClick={() => ReqAccept()}>
              <img
                  src={img && img !== ''
                      ? 'https://bshop.kz/beauty/AvatarPhotos/' + img
                      : 'https://bshop.kz/beauty/AvatarPhotos/0.png'}
                  alt="logo" />
          </div>

          <div className={s.item_client_req_info}>
              <div className={s.item_client_req_info_name_id}>
                  <div className={s.item_client_req_info_nameClient}>{FIO}</div>
                  <div className={s.item_client_req_info_date}>{date.substr(5)}</div>
                  <div className={s.item_client_req_info_time}>{time}</div>

                  
              </div>
              <div className={s.item_client_req_info_orderName}>{orderName}</div>

              <div className={s.item_client_req_info_comm_price}>



                  <div className={s.item_client_req_info_price}>{price} тнг</div>
              </div>

            

           

           

          

              {/* <div className={s.item_client_req_info_comm      }>{comm}</div> */}
          </div>
      </div>
     
        <Popup trigger={<button className={s.dropdown_comm}> Комментарий клиента </button>} modal nested>
            <div className={s.modal}>

                <div className={s.header}> Комментарий </div>
                <div className={s.content}>
                    <p>
                        {comm}
                    </p>
                </div>




            </div>

        </Popup>
                
        <AcceptModul orderName={orderName} date={date} id={id} phome={phome} price={price} time={time} clientId={clientId} />
        </div>
  );
};

export default ItemClientRequest;
