/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import './style/Registration_form_style.css';
import Registration_text_field from './Registration_text_field';
import { ToastContainer, toast } from 'react-toast';

class Registartion_form_client extends React.Component {
  state = {
    FIO: '',
    SalonName: '',
    Adress: '',
    Phone: '',
    Email: '',

    NameError: '',
    PhoneError: '',
    EmailError: '',
  };

  wave_error = () => {
    if (this.state.FIO === '') {
      toast('Ошибка! Пустое поле ФИО', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (Boolean(this.state.NameError) === true) {
      toast('Ошибка! Некорректно введенное поле ФИО', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (this.state.Phone === '') {
      toast('Ошибка! Пустое поле Телефона', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (Boolean(this.state.PhoneError) === true) {
      toast('Ошибка! Некорректно введенное поле Телефон', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (this.state.Email === '') {
      toast('Ошибка! Пустое поле Email', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    } else if (Boolean(this.state.EmailError) === true) {
      toast('Ошибка! Некорректно введенное поле Email', {
        backgroundColor: '#323131',
        color: '#ffffff',
      });
    }
  };

  wave_success = () =>
    toast('Отлично! Вы успешно зарегистрировались!', {
      backgroundColor: '#10df3d',
      color: '#ffffff',
    });

  validateName = (name) => {
    const regex = /[A-Za-zА-Яа-я]{2,}/;
    return !regex.test(name);
  };

  validateEmail = (name) => {
    const emregex = /\S+@\S+\.\S+/;
    return !emregex.test(name);
  };

  validatePhone = (name) => {
    var phone_len = name.length;
    let isLetter = /[a-zа-яё]/i.test(name);
    if (phone_len === 18 && isLetter === false) {
      return false;
    } else {
      return true;
    }
  };

  onNameBlur = () => {
    const { FIO } = this.state;
    const NameError = this.validateName(FIO);
    console.log(NameError);
    return this.setState({ NameError });
  };

  onPhoneBlur = () => {
    const { Phone } = this.state;
    const PhoneError = this.validatePhone(Phone);
    console.log(PhoneError);
    return this.setState({ PhoneError });
  };

  onEmailBlur = () => {
    const { Email } = this.state;
    const EmailError = this.validateEmail(Email);
    console.log(EmailError);
    return this.setState({ EmailError });
  };
  //_____________________________//_____________________________

  //_____________________________//_____________________________
  onFIOChange = (event) =>
    this.setState({
      FIO: event.target.value,
    });

  onPhoneChange = (event) => {
    this.setState({
      Phone: event.target.value,
    });
    var old = 0;

    var curLen = event.target.value.length;

    if (curLen < old) {
      old--;
      return;
    }

    if (curLen === 1) event.target.value = event.target.value = '+7 (';

    if (curLen === 7) event.target.value = event.target.value + ')-';

    if (curLen === 12) event.target.value = event.target.value + '-';

    if (curLen === 15) event.target.value = event.target.value + '-';

    if (curLen > 18) event.target.value = event.target.value.substring(0, 17);
    old++;
  };

  onEmailChange = (event) => {
    this.setState({
      Email: event.target.value,
    });
  };

  SendRegForm = async (e) => {
    e.preventDefault();

    if (
      Boolean(this.state.NameError) === false &&
      Boolean(this.state.PhoneError) === false &&
      Boolean(this.state.EmailError) === false &&
      this.state.FIO !== '' &&
      this.state.Phone !== '' &&
      this.state.Email !== ''
    ) {
      this.wave_success();
      console.log('SendRegForm:');
      const regdata = new FormData();
      regdata.append('FIO', this.state.FIO);
      regdata.append('Phone', this.state.Phone);
      regdata.append('Email', this.state.Email);
      regdata.append('status', '0');

      let response = await fetch(`https://bikli.kz/apiBeauty/BRegistration_form2.php`, {
        method: 'POST',
        body: regdata,
      });

      let commits = await response.json(); // читаем ответ в формате JSON
      console.log(commits.form);
    } else {
      this.wave_error();
    }
  };

  //_____________________________//_____________________________

  //_____________________________//_____________________________

  render() {
    const { NameError, PhoneError, EmailError } = this.state;

    return (
      <div className="reg_body">
        <div className="reg_wrapper">
          <h1 className="reg_title" style={{ marginTop: '20px' }}>
            Регистрация
          </h1>

          <div className="registration_form">
            <Registration_text_field
              name="FIO"
              onChange={this.onFIOChange}
              onBlur={this.onNameBlur}
              error={NameError}
              placeholder="Ф.И.О."
              className="reg_placeholder"
            />

            <form name="registration" className="PhoneInputReset">
              <Registration_text_field
                name="Phone"
                onChange={this.onPhoneChange}
                onBlur={this.onPhoneBlur}
                error={PhoneError}
                placeholder="Телефон"
                className="reg_placeholder_phone"
              />
              <button type="reset" className="reg_reset_btn"></button>
            </form>

            <Registration_text_field
              name="Email"
              onChange={this.onEmailChange}
              onBlur={this.onEmailBlur}
              error={EmailError}
              placeholder="Email"
              className="reg_placeholder"
            />

            <div className="inputGroup">
              <button onClick={this.SendRegForm} className="reg_btn">
                Отправить
              </button>
              <ToastContainer position="bottom-center" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Registartion_form_client;
