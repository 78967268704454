import React from 'react';
import ContentLoader from 'react-content-loader';
import s from './MasterInCatalog.module.css';

const BeautyPostsLoadingBlock = () => {
  return (
    <ContentLoader
      className={s.item_bshop}
      style={{ marginTop: '12px' }}
      speed={2}
      width={290}
      height={350}
      viewBox="0 0 170 221"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="-1" y="5" rx="0" ry="0" width="168" height="215" />
    </ContentLoader>
  );
};

export default BeautyPostsLoadingBlock;
