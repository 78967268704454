import React, { useEffect, useRef, useState } from 'react';
import s from './req.module.css';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import dayjs from 'dayjs';
import objToFd from '../../../common/helpers/objToFd';
import beautyApi from '../../../api/beautyApi';
import { useSelector, useDispatch } from 'react-redux';
import { DisplayType, DopDisplayType } from '../../../redux/action';

const AcceptModulforClient = (props) => {

    const {
        register,
        handleSubmit,
        reset
      } = useForm();
  
      const [dropDownDate, setDropDownDate] = useState(false);
      const [dropDownDop, setDropDownDop] = useState(false);
      const [acceptDate, setAcceptDate] = useState(props.date);

      const [onDate, setOnDate] = useState('Дата');
      const dispatch = useDispatch();

      const display_flex = useSelector((state) => state.DisplayType);
      const dop_display_flex = useSelector((state) => state.DopDisplayType);

      const masterId = useSelector((state) => state.accountBeauty.id);

    //   setDropDownDate(false)
    //   setDropDownTime(false)

    const callDay = (clikedDay) => {
        // console.log(dayjs(clikedDay).format('YYYY-MM-DD'))
        let Date = dayjs(clikedDay).format('YYYY-MM-DD');
        // console.log(Date)
        setOnDate(dayjs(clikedDay).format('YYYY-MM-DD'));

        };

    const minusButton = (clikedDay) => {
        var tomorrow = new Date(acceptDate);
        var day = tomorrow.setDate(tomorrow.getDate()-1);
        setAcceptDate(dayjs(day).format('YYYY-MM-DD'))
    }; 
    const plusButton = () => {
        var tomorrow = new Date(acceptDate);
        var day = tomorrow.setDate(tomorrow.getDate()+1);
        setAcceptDate(dayjs(day).format('YYYY-MM-DD'))

    };
    const onSubmit = async (data) => {
        const obj = {
            ...data
        };

        obj.order == '' ?  obj.order = props.orderName : obj.order = obj.order;
        obj.time == 'Время' ?  obj.time = props.time : obj.time = obj.time;
        obj.price == '' ?  obj.price = props.price : obj.price = obj.price;
        obj.comm == '' ?  obj.comm = 'Мастер не оставил комментария' : obj.comm = obj.comm;

        const fd = new FormData();
        await objToFd(fd, obj);
        await fd.append('date', acceptDate);
        await fd.append('orderId', props.id);
        await fd.append('clientId', masterId);

        beautyApi.MasterReqAccept(fd);
        reset();
        dispatch(DopDisplayType('block'));
        setDropDownDop(!dropDownDop);
        };

  return (
    <Popup
    trigger={<button className={s.dropdown_accept}>Принять</button>}
    modal
    nested
  >
    {close => (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <div className={s.modal}>
        <button className={s.close} onClick={close}>
          &times;
        </button>
        <div className={s.header}> Заявка </div>
        <div className={s.content}>
                
        </div>
            
           
  

      </div>
      </form>
    )}
  </Popup>
)
       
  

};

export default AcceptModulforClient;
