const baseBeautyUrl = 'https://bshop.kz/beauty/beautyApi/';

const beautyApi = {
  fetchMastersInCatalog: async (counItems, profName) => {
    const response = await fetch(
      baseBeautyUrl + `CatAndOrder/getCategory.php?start=0&end=${counItems}&prof=${profName}`,
    );
    const data = response.json();
    return data;
  },

  fetchServicesInCatalog: async (counItems, profName) => {
    const response = await fetch(
      baseBeautyUrl + `CatAndOrder/PullOrderCat.php?start=0&end=${counItems}&prof=${profName}`,
    );
    const data = response.json();
    return data;
  },

  fetchServicesInReq: async () => {
    const response = await fetch(
      baseBeautyUrl + `PullOrderCatReq.php`,
    );
    const data = response.json();
    return data;
  },

  fetchServicesInReq2: async () => {
    const response = await fetch(
      baseBeautyUrl + `PullOrderCatReq2.php`,
    );
    const data = response.json();
    return data;
  },

  fetchServicesInReq3: async () => {
    const response = await fetch(
      baseBeautyUrl + `PullOrderCatReq3.php`,
    );
    const data = response.json();
    return data;
  },
  
  ClientServiceRequests: async () => {
    const response = await fetch(baseBeautyUrl + `PullOrderTorgy2.php`, { method: 'POST' }).then(
      (response) => response.json(),
    );
    // console.log(response);
    return response;
  },

  MyRequests: async (RServiceEnd, RServicesValue, myId) => {
    const data = new FormData();
    data.append('start', RServiceEnd);
    data.append('end', RServicesValue);
    data.append('clientId', myId);


    const response = await fetch(baseBeautyUrl + `PullHistoryOrder.php`, { method: 'POST', body: data, }).then(
      (response) => response.json(),
    );
    // console.log(response);
    return response;
  },

  ClientService: async (fd) => {
    const response = await fetch(baseBeautyUrl + `torgy/ApplyOrder.php`, {
      method: 'POST',
      body: fd,
    });
    const data = response;
    return data;
  },

  MasterReqAccept: async (fd) => {
    const response = await fetch(
      `https://beauty.bikli.kz/beautyApi/torgy/beautyOrderApplyMaster.php`,
      {
        method: 'POST',
        body: fd,
      },
    );
    const data = response;
    return data;
  },

  fetchDataMaster: async (obj) => {
    const response = await fetch(baseBeautyUrl + `profil/profil.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  fetchBlogMaster: async (obj) => {
    const response = await fetch(baseBeautyUrl + `profil/PullPubImg.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },

  uploadAvatarUser: async (obj) => {
    const response = await fetch(baseBeautyUrl + `uploadImg.php`, {
      method: 'POST',
      body: obj,
    });
    const data = response.json();
    return data;
  },
};

export default beautyApi;
