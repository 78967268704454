import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import beautyApi from '../../../api/beautyApi';
import SignIn from '../../../companents/SignIn/SignIn';
import { accountBeauty } from '../../../redux/action';
import s from '../pageMaster.module.css';
import no_avatar from '../style/no-avatar.png';
import MyRequests from '../../../companents/headerNew/MyRequests';

const ProfileBeautyClient = () => {
  const dispatch = useDispatch();

  const [signIn, setSignIn] = useState(false);
  const login = JSON.parse(localStorage.getItem('account'));
  const accountBeautyInfo = useSelector((state) => state.accountBeauty);
  const avatar = useRef();
  const [avatarLoading, setAvatarLoading] = useState(no_avatar);
  const [menu, setMenu] = useState('req');

  const [borderBottom, setBorderBottom] = useState('4px solid transparent');
  const [borderImage, setBorderImage] = useState('linear-gradient(to left, rgb(212, 50, 216) -0.07%, rgb(255, 96, 141) 51.49%, rgb(244, 165, 130) 99.93%) 1 / 1 / 0 stretch');
  const [color, setColor] = useState('#404246');
  const [fontWeight, setFontWeight] = useState('800');

  useEffect(() => {
    if (login) {
      const regdata = new FormData();

      regdata.append('phone', login.login);
      regdata.append('pass', login.pass);

      fetch(`https://bshop.kz/beauty/beautyApi/profil/logIn.php`, {
        method: 'POST',
        body: regdata,
      })
        .then(async (res) => await res.json())
        .then((res) => {
          setSignIn(!signIn);
          dispatch(accountBeauty(res[0]));
        });
    }
  }, []);

  const exitAccount = (e) => {
    e.preventDefault();
    dispatch(accountBeauty([]));
    localStorage.removeItem('account');
  };

  const editData = () => {
    if (avatar.current.files[0]) {
      const form = new FormData();
      form.append('userfile', avatar.current.files[0], avatar.current.files[0].name);
      form.append('id', accountBeautyInfo.id);
      const uploadAvatar = beautyApi.uploadAvatarUser(form);
      uploadAvatar.then((res) => res).then((res) => res === true && window.location.reload());
    }
  };

  const showR = (e) => {
    setMenu(e);
    e = 'отзывы' ? hideR() : console.log();
  }

  const hideR = () => {

    setBorderBottom('');
    setBorderImage('');
    setColor('');
    setFontWeight('');

  }

  if (accountBeautyInfo.Email || login) {
    // статус 0 - клиент, 1 - мастер
      return (
        <>
          <div className={s.header}>
            <div className={s.avatar}>
              {accountBeautyInfo.img && accountBeautyInfo.img != '' ? (
                <img
                  src={`https://bshop.kz/beauty/AvatarPhotos/${accountBeautyInfo.img}`}
                  alt="avatar"
                />
              ) : (
                <img src={no_avatar} alt="avatar" />
              )}
            </div>
            <div className={s.info}>
              <div className={s.name_person}>{accountBeautyInfo.FIO}</div>
              <div className={s.proffesion_person}> Номер: {accountBeautyInfo.Phone}</div>
              <div className={s.proffesion_person}> Email: {accountBeautyInfo.Email}</div>
            </div>
          </div>
          <form name="registration">
            <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
              <input
                id="file-input"
                type="file"
                name="file"
                multiple
                style={{ display: 'none' }}
                ref={avatar}
                onChange={() => editData()}
                accept="image/jpg, image/png, image/jpeg"
              />
              <label className={s.buttonBeauty} htmlFor="file-input" style={{}}>
                Изменить / Добавить фото
              </label>
              <button className={s.buttonBeauty} onClick={(e) => exitAccount(e)}>
                Выйти
              </button>
            </div>
          </form>

          <div className={s.container}>
            <div className={s.nav}>
                <ul className={s.uv}>
                    <li style={{borderBottom: `${borderBottom}`, borderImage: `${borderImage}`, color: `${color}`, fontWeight: `${fontWeight}`}} onClick={() => showR('req')}><a>Мои Заявки</a>
                    </li>
                    <li onClick={() => showR('отзывы')}><a>отзывы</a>
                    </li>
                    <li onClick={() => showR('Избранное')}><a>Избранное</a>
                    </li>
                </ul>
            </div>
        </div>

        {
        
        menu == 'req' ? 
            <div className={s.myRequests}>
                <MyRequests img = {accountBeautyInfo.img} />
            </div>
            : menu
        }
 

        </>
      );

  } else {
    return (
      <div>
        <SignIn />
      </div>
    );
  }
};

export default ProfileBeautyClient;
